async function copyToClipboard(value: string | undefined) {
  if (!value) return false
  try {
    await navigator.clipboard.writeText(value)
    return true
  } catch (e) {
    alert('Sorry, dein Browser unterstützt diese Funktion nicht.')
    return false
  }
}

export default copyToClipboard
